import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import FlexContainer from 'react-app/components/FlexContainer';
import GridContainer from 'react-app/components/GridContainer';
import { useTheme } from 'styles';

const useClasses = createClasses((theme, {disableGutterBottom, spacingType = 'gutter'}) => ({
	root: {
		...(!disableGutterBottom && ({
			'&:not(:last-child):not(:empty)': {
				marginBottom: theme.config[`${spacingType}Spacing`]
			}
		}))
	}
}), {
	name: 'CardContainer'
});

function CardContainer(props) {
	const theme = useTheme();
	const classes = useClasses(props);

	const {
		classes: classesProp,
		className,
		children,
		cards: cardsProp = [],
		CardProps,
		CardComponent = 'div',
		maxWidth = theme.config.containerMaxWidth,
		gridMode,
		spacingType,
		disableGuttersX,
		disableGutterBottom,
		centered,
		stretch = true,
		...rest
	} = props;

	const Container = gridMode ? GridContainer : FlexContainer;

	const cards = children ? children : (
		Array.isArray(cardsProp) && cardsProp.map((card, key) => (
			<CardComponent
				key={key}
				{...card}
				{...CardProps}
				{...(CardProps?.preload && key === 0
					? { preload: true }
					: null
				)}
			/>
		))
	);

	return (
		<Container
			{...rest}
			stretch={stretch}
			disableGutters={disableGuttersX}
			{...(!gridMode && {
				centered
			})}
			className={classes.root}
			maxWidth={maxWidth}
		>
			{cards}
		</Container>
	);
}

CardContainer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	component: PropTypes.elementType,
	cards: PropTypes.array,
	CardProps: PropTypes.object,
	gridMode: PropTypes.bool,
	disableGuttersX: PropTypes.bool,
	spacingType: PropTypes.oneOf(['container', 'gutter'])
};

export default React.memo(CardContainer);
