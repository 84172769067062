import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, gutterSpacing } from '../../styles';
import Container from '../Container';

const defaultProps = {
	gap: {...gutterSpacing}
};

const useClasses = createClasses((theme, {disableGutters, dense, autoFit, gap = defaultProps.gap, centered}) => ({
	root: {
		gap: dense ? gap.xs : gap,
		...(!disableGutters && {
			paddingX: theme.config.containerSpacing
		}),
		...(centered && {
			justifyContent: 'center'
		}),
		'&& > *': {
			...(autoFit ? {
				flexBasis: 'auto'
			} : {
				'--gap': theme.spacing(gap.xs),
				'--columns': 1,
				[theme.breakpoints.up('sm')]: {
					...(!dense && {
						'--gap': theme.spacing(gap.sm || gap.xs)
					})
				},
				[theme.breakpoints.up('md')]: {
					'--columns': 2,
					...(!dense && {
						'--gap': theme.spacing(gap.md || gap.sm || gap.xs)
					})
				},
				[theme.breakpoints.up('lg')]: {
					'--columns': 3,
					...(!dense && {
						'--gap': theme.spacing(gap.lg || gap.md || gap.sm || gap.xs)
					})
				},
				[theme.breakpoints.up('desktop')]: {
					'--columns': 4,
					...(!dense && {
						'--gap': theme.spacing(gap.desktop || gap.lg || gap.md || gap.sm || gap.xs)
					})
				},
				flexBasis: 'calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)))'
			})
		}
	}
}), {
	name: 'RaFlexContainer'
});

function FlexContainer(props) {
	const {
		classes: classesProp,
		className,
		children,
		autoFit,
		dense,
		gap = defaultProps.gap,
		centered,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Container
			maxWidth={false}
			{...rest}
			className={classes.root}
		>
			{children}
		</Container>
	);
}

FlexContainer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	autoFit: PropTypes.bool,
	dense: PropTypes.bool,
	centered: PropTypes.bool,
	gap: PropTypes.object
};

export default React.memo(FlexContainer);
